
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { getOperRecordDetail } from "@/API/rv/rvSipo";
export default defineComponent({
  setup() {
    const { t, locale } = useI18n();

    const columns = [
      {
        title: t('retailValidationSipo.role'),
        dataIndex: "userName",
        key: "userName"
      },
      {
        title: t('retailValidationSipo.action'),
        dataIndex: "actionName",
        key: "actionName"
      },
      {
        title: t('retailValidationSipo.content'),
        dataIndex: "content",
        key: "content",
        slots: {customRender: "content"}
      },
      {
        title: t('retailValidationSipo.operationTime'),
        dataIndex: "createdAt",
        key: "createdAt"
      }
    ];

    const data = ref([]);
    const current = ref<number>(1);
    const size = ref<number>(20);
    const total = ref<number>(0);
    const initData = () => {
      const params = {
        vinNo: sessionStorage.getItem("sipoRecordVinNo"),
        dealerCode: sessionStorage.getItem("sipoRecordDealerCode"),
        current: current.value,
        size: size.value
      };
      getOperRecordDetail({ params }).then(res => {
        data.value = res.content;
        total.value = res.totalElements;
      });
    };
    initData();
    const pageChange = (page: number, pageSize: number) => {
      current.value = page > 0 ? page : 1;
      size.value = pageSize;
      initData();
    };
    const sizeChange = (page: number, pageSize: number) => {
      current.value = page > 0 ? page : 1;
      size.value = pageSize;
      initData();
    };
    return {
      columns,
      data,
      pageChange,
      sizeChange,
      total,
      size,
      locale
    };
  }
});
